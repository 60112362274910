import { Dialog } from '@headlessui/react';

import ExitPopUp from '@/assets/icons/ExitPopUp.svg';

import type { FunctionComponent } from 'react';
import type { TypePopUpBaseProps } from './types';

/**
 * PopUpBase
 */
export const PopUpBase: FunctionComponent<TypePopUpBaseProps> = ({
  className = '',
  state,
  children,
  funcionOnClose,
  thumbsChildren,
  desc,
  title,
  type = 'base',
  bgColor = '#fff',
}: TypePopUpBaseProps) => (
  <Dialog
    open={state}
    onClose={funcionOnClose}
    className="fixed inset-0 z-50 grid place-items-center"
  >
    <Dialog.Overlay
      className="fixed inset-0 bg-[rgba(21,33,39,0.7)]"
      onClick={funcionOnClose}
    />

    <Dialog.Panel className="z-50 flex flex-col items-center overflow-hidden">
      <div className="flex h-full flex-col items-center  justify-around gap-5">
        <div
          style={{ backgroundColor: `${bgColor} !important` }}
          className={`flex flex-col rounded-md text-primary-400 sm:justify-around ${
            type === 'galery' ? 'bg-transparent sm:bg-white' : ''
          } h-fit w-full max-w-[calc(100vw-2rem)] p-4 sm:h-auto sm:w-auto sm:p-5 ${className}`}
        >
          <div
            className={`mx-2 flex flex-row justify-between gap-4 sm:mb-2 sm:mt-0 ${
              type === 'galery' ? 'my-5' : 'my-2'
            }`}
          >
            <div className="flex flex-col gap-1">
              {title && (
                <h3 className="text-heading text-heading--h3">{title}</h3>
              )}

              {desc && (
                <span className="text-body text-body--secondary--bold">
                  {desc}
                </span>
              )}
            </div>

            <ExitPopUp
              heigth={32}
              width={32}
              className="flex-shrink-0 hover:cursor-pointer"
              onClick={funcionOnClose}
            />
          </div>

          <div
            className={
              type === 'galery'
                ? 'min-h-[60vh] min-w-[80vw]'
                : type === 'iframe'
                ? desc
                  ? 'relative min-h-[calc(100vh-68px)] min-w-[100vw] sm:min-h-[60vh] sm:min-w-[80vw]'
                  : 'relative min-h-[calc(100vh-48px)] min-w-[100vw] sm:min-h-[60vh] sm:min-w-[80vw]'
                : 'h-[60vh] overflow-y-auto sm:h-auto sm:w-auto'
            }
          >
            {children}
          </div>
        </div>

        {thumbsChildren && (
          <div className="mb-3 min-h-fit sm:mb-0">{thumbsChildren}</div>
        )}
      </div>
    </Dialog.Panel>
  </Dialog>
);

PopUpBase.displayName = 'PopUpBase';
