import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import LinkNext from 'next/link';

import { Button, Caption, Link, Multimedia } from '@/atoms';
import PopUpCalendly from '@/organisms/pop-up-calendly';

import type { TypeTextColorVariant } from '@/components/types';
import type { FunctionComponent } from 'react';
import type { TypeCrossProps, TypeCrossVariant } from './types';

const VARIANT_MAPS: Record<TypeCrossVariant, string> = {
  Derecha: 'flex md:flex-row-reverse',
  Izquierda: 'flex md:flex-row',
};

const VARIANT_TEXT_MAPS: Record<TypeTextColorVariant, string> = {
  primary: 'text-primary-400',
  secondary: 'text-secondary-400',
  alternative: 'text-white',
};

const PopUpBookingLavanda = dynamic(
  () => import('@/organisms/pop-up-booking-lavanda'),
  { ssr: false }
);

/**
 * Cross
 */
export const Cross: FunctionComponent<TypeCrossProps> = ({
  className = '',
  propsButton,
  description,
  propsLink,
  widerPart,
  posicionText,
  title,
  backgroundColor,
  caption,
  multimedia,
  textColor,
  hasPopUp,
  accommodationSelect,
  buildingSelect,
  calendlyProps,
  popUpTipe,
  defaultApartment,
  defaultBuilding,
}: TypeCrossProps) => {
  const [formOpen, setFormOpen] = useState(false);

  const popUpToggle = () => {
    setFormOpen(!formOpen);
  };

  const [calendlyOpen, setCalendlyOpen] = useState(false);

  const calendlyToggle = () => {
    setCalendlyOpen(!calendlyOpen);
  };

  return (
    <>
      {hasPopUp && accommodationSelect && buildingSelect && (
        <PopUpBookingLavanda
          apartmentsByBuilding={accommodationSelect}
          buildings={buildingSelect}
          show={formOpen}
          onClose={popUpToggle}
          defaultBuilding={defaultBuilding}
          defaultApartment={defaultApartment}
        />
      )}
      <div
        className={`${VARIANT_MAPS[posicionText]} relative h-fit  flex-col  rounded-md shadow-md md:flex-row md:gap-8 md:shadow-none ${className}`}
      >
        {multimedia ? (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Multimedia
            {...multimedia}
            imageAbove={undefined}
            src={multimedia?.src ? multimedia.src : undefined}
            className="flex !h-[200px] md:hidden"
          />
        ) : null}

        {multimedia.imageAbove && (
          <div className="absolute left-4 top-[200px]  h-14 w-14 -translate-y-1/2 md:hidden">
            <Image
              objectFit="cover"
              layout="fill"
              alt="Logo Be Casa"
              src={multimedia.imageAbove}
            />
          </div>
        )}

        <div
          className={`flex w-full flex-col justify-between gap-5  rounded-b-md  px-6 pb-7 pt-10 shadow-none md:rounded-md md:py-8 md:shadow-sm ${
            widerPart === 'Imagen' && posicionText === 'Derecha'
              ? 'md:w-[53%]'
              : 'md:w-[73%]'
          }`}
          style={{
            backgroundColor: backgroundColor
              ? `var(--tw-color-${backgroundColor})`
              : '#fff',
          }}
        >
          <div
            className={`flex flex-col gap-4 ${
              widerPart === 'Imagen' && posicionText === 'Derecha'
                ? 'lg:px-1'
                : 'lg:px-14'
            }  ${VARIANT_TEXT_MAPS[textColor]}`}
          >
            {caption && <Caption variant={textColor}>{caption}</Caption>}

            <h3 className="text-heading text-heading--h3 -mt-1 mb-3">
              {title}
            </h3>

            {description && (
              <span className="text-body text-body--primary">
                {description}
              </span>
            )}
          </div>

          <div
            className={` flex w-full flex-col flex-wrap items-center gap-10  md:w-auto md:flex-row ${
              widerPart === 'Imagen' && posicionText === 'Derecha'
                ? 'mr-auto'
                : 'ml-auto'
            }`}
          >
            {!hasPopUp ? (
              propsButton?.src &&
              propsButton?.children && (
                <LinkNext
                  href={propsButton.src}
                  aria-label="Solicitar Visita"
                  passHref
                  prefetch={false}
                >
                  <Button
                    className="text-links text-links--default  w-full  md:!w-fit"
                    aria-label="Solicitar Visita"
                    {...propsButton}
                    as="a"
                  />
                </LinkNext>
              )
            ) : (
              <Button
                className="text-links text-links--default  w-full  md:!w-fit"
                onClick={popUpTipe === 'Lavanda' ? popUpToggle : calendlyToggle}
                size={propsButton.size}
                variant={propsButton.variant}
              >
                {propsButton.children}
              </Button>
            )}

            {/* end CTA */}

            {propsLink?.src && propsLink?.label && (
              <LinkNext href={propsLink.src} passHref prefetch={false}>
                <Link
                  className="text-links text-links--default"
                  variant={propsLink.variant}
                  aria-label="Contactanos"
                >
                  {propsLink.label}
                </Link>
              </LinkNext>
            )}
          </div>
        </div>

        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/* @ts-ignore*/}
        <Multimedia
          {...multimedia}
          className={`hidden overflow-hidden shadow-none md:flex md:rounded-md md:shadow-sm ${
            widerPart === 'Imagen' ? 'md:w-[73%]' : 'md:w-[27%]'
          }`}
        />
      </div>
      <>
        <PopUpCalendly
          open={calendlyOpen}
          onClose={calendlyToggle}
          formProps={calendlyProps}
        />
      </>
    </>
  );
};

Cross.displayName = 'Cross';
