//eslint-disable-next-line
//@ts-nocheck
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';

import { Button, Select } from '@/atoms';

import type { FunctionComponent } from 'react';
import type { TypeCalendlyFormProps } from './types';

/**
 * CalendlyForm
 */
export const CalendlyForm: FunctionComponent<TypeCalendlyFormProps> = ({
  className = '',
  cities,
  buildings,
  provinces,
}: TypeCalendlyFormProps) => {
  const { t } = useTranslation('common');
  const { handleSubmit, watch, register, setValue, ...methods } =
    useForm<TypeCalendlyFormProps>();

  const mapOfCities = new Map(cities);
  const mapOfBuildings = new Map(buildings);
  const watchProvince = watch('provinces', provinces[0].value.toString());
  const watchBuildings = watch('buildings');
  const watchCities = watch(
    'cities',
    mapOfCities.get(provinces[0].value.toString())[0].value
  );

  const [citiesSelect, setCitiesSelect] = useState(
    mapOfCities.get(provinces[0].value.toString())
  );

  const [buildingsSelect, setBuildingsSelect] = useState(
    mapOfBuildings.get(watchCities)
  );

  const [buttonActive, setButtonActive] = useState();

  // Esta funcion tiene encuenta la provincia selecccionada.
  // Despues establezco el valor de las ciudades para
  // tener el primer objecto de las lista seleccionado
  useEffect(() => {
    setCitiesSelect(mapOfCities.get(watchProvince));
    setValue('cities', mapOfCities.get(watchProvince)[0].value);
  }, [watchProvince]);

  useEffect(() => {
    setValue(
      'save',
      buildingsSelect?.find((build: any) => build.value === watchBuildings)
        ?.value
    );
  }, [watchBuildings]);

  // Los dos useEffect siguentes me establecen los edificios a seleccionar.

  // Este primero tiene encuenta si se ha cambiado la lista de ciudades
  // si es asi mira cual a sido seleccionada y despues me guarda el valor.
  useEffect(() => {
    setBuildingsSelect(mapOfBuildings.get(watchCities));
    // Me Guardo esta valor para el submmit
    // Si lo guardo como buildings da problemas
    setButtonActive(mapOfBuildings.get(watchCities)[0].disabled);

    setValue('save', mapOfBuildings.get(watchCities)[0].value);
  }, [watchCities]);

  // Este tiene encuenta si la lista de las ciudades a cambiado, que
  // es lo mismo que decir que se ha cambiado la provincia
  // por eso se mira la provincia seccionada y cojo el primer valor seleccionado.
  // depsues cojo el valor del primera ciudad y establezco los edificios
  useEffect(() => {
    setBuildingsSelect(
      mapOfBuildings.get(mapOfCities.get(watchProvince)[0].value)
    );

    setButtonActive(
      mapOfBuildings.get(mapOfCities.get(watchProvince)[0].value)[0].disabled
    );

    // Me Guardo esta valor para el submmit
    // Si lo guardo como buildings da problemas
    setValue(
      'save',
      mapOfBuildings.get(mapOfCities.get(watchProvince)[0].value)[0].value
    );
  }, [citiesSelect]);

  const onSubmit = (data: any) => {
    const url = mapOfBuildings
      .get(data.cities)
      ?.find((temp: any) => temp.value === data.save)?.url;

    if (url) {
      window.open(`${url}`, '_blank', 'noopener noreferrer');
    }
  };
  return (
    <div className={`relative ${className}`}>
      <div className="absolute -top-[62%] left-[80%] hidden md:block">
        <div className="u-aspect-square relative w-10 md:w-14">
          <Image
            src="/assets/img/logo/logo-brown.svg"
            alt="Logo BecasaEssentials"
            aria-label="Logo BecasaEssentials"
            objectFit="contain"
            layout="fill"
          />
        </div>
      </div>
      <FormProvider
        handleSubmit={handleSubmit}
        register={register}
        setValue={setValue}
        watch={watch}
        {...methods}
      >
        <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmit)}>
          <Select
            label={t('components.molecules.calendlyForm.provinces')}
            name="provinces"
            elements={provinces}
          />
          <Select
            label={t('components.molecules.calendlyForm.cities')}
            name="cities"
            elements={citiesSelect}
          />
          <Select
            label={t('components.molecules.calendlyForm.buildings')}
            name="buildings"
            elements={buildingsSelect}
          />
          <Button
            type="submit"
            variant="secondary"
            size="large"
            className="mt-4 w-full md:w-1/2"
            disabled={buttonActive}
          >
            Solicitar una visita
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

CalendlyForm.displayName = 'CalendlyForm';
