import { Container } from '@/atoms';
import { Cross } from '@/molecules';

import type { FunctionComponent } from 'react';
import type { TypeCrossTemplateProps } from './types';

/**
 * CrossTemplate
 */
export const CrossTemplate: FunctionComponent<TypeCrossTemplateProps> = ({
  crossProps,
  cutLevel = 50,
  cutLevelMobile = 50,
  ...config
}: TypeCrossTemplateProps) => (
  <Container
    className="py-6 lg:py-20"
    cutLevel={cutLevel === null ? 50 : cutLevel}
    cutLevelMobile={cutLevelMobile === null ? 50 : cutLevel}
    {...config}
  >
    <Cross {...crossProps} />
  </Container>
);

CrossTemplate.displayName = 'CrossTemplate';
